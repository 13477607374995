<template>
    <div class="lesson">
        <p class="h3">
            {{ lesson.lesson.lesson.title }}
        </p>

        <div v-if="lesson.lesson.lesson.videos.length">
            <div
                v-for="(video, index) in lesson.lesson.lesson.videos"
                :key="video._id"
                v-if="video.hasAccessByAccessLevel"
            >
                <template v-if="lesson.vdocipher && video.vdocipher && video.vdo">
                    <template v-if="lesson.isVideoEnabled">
                        <div class="vdocipher-container lesson__video" :id="'vdocipher-' + video.vdo.otp" style="width:1280px;max-width:100%;height:auto;"></div>
                    </template>
                    <template v-else>
						<div class="error">Превышен лимит устройств</div>
                    </template>
                </template>
                <template v-else>
                    <template v-if="lesson.isVideoEnabled">
                        <a
                            v-if="(lesson.lesson.lesson.type !== 'additional' || video.additionalType !== 'answer')"
                            class="lesson__video"
                            target="_blank"
                            :href="lesson.key ? video.url + '?sn=' + lesson.key : video.url"
                        >
                            <div class="imgBlock" v-if="lesson.lesson.lesson.type !== 'additional'">
                                <img
                                    class="imgBlock"
                                    alt="video"
                                    :src="
                                    video.preview
                                        ? $oldClientTestUrl + video.preview
                                        : 'https://edu.gerchik.ru/img/videoPreview.png'
                                "
                                />
                                <img
                                    class="imgBlock__btn imgBlock__btn--play"
                                    alt="video"
                                    src="@/assets/img/pages/study/play2.png"
                                />
                            </div>
                            <iframe
                                v-else
                                width="100%"
                                height="376"
                                :src="lesson.key ? video.url + '?sn=' + lesson.key : video.url"
                                title="Vimeo video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </a>
                    </template>
                    <template v-else>
                        <div class="error">Превышен лимит устройств</div>
                    </template>
                </template>
            </div>
            <div
                v-else
                class="lesson__video"
            >
                <div class="imgBlock" v-if="lesson.lesson.lesson.type !== 'additional'">
                    <img
                        class="imgBlock imgBlock--disabled"
                        alt="video"
                        :src="
                            video.preview
                                ? $oldClientTestUrl + video.preview
                                : $oldClientTestUrl + 'img/videoPreview.png'
                        "
                    />
                    <img
                        class="imgBlock__btn imgBlock__btn--lock"
                        alt="video"
                        src="@/assets/img/pages/study/lock2.svg"
                    />
                </div>
                <iframe
                    v-else
                    width="100%"
                    height="376"
                    :src="lesson.key ? video.url + '?sn=' + lesson.key : video.url"
                    title="Vimeo video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    class="imgBlock imgBlock--disabled"
                ></iframe>
            </div>

            <div v-if="lesson.vdocipher && lesson.lesson.lesson.videos[0].vdocipher"></div>
            <div class="lesson__buttons" v-else>
                <StudyInfoProtector
                    v-if="isVisibleModal"
                    :onClose="() => onCloseModal()"
                />
                <ButtonVideoProblem title="трудности с воспроизведением ?" />
            </div>
        </div>

        <LessonMaterials
            v-if="lesson.materials.length"
            title="Дополнительные материалы"
            :materials="lesson.materials"
        />

        <Button
            v-if="lesson.showHWButton && lesson.homeTasks && lesson.homeTasks.length"
            class="lesson__items"
            :title="
                Number(this.$route.params.block_priority) === 10
                    ? 'Написание сделки'
                    : 'Выполнить задание'
            "
            :isLink="true"
            :link="
                '/' +
                    $route.params.slug +
                    '/study/' +
                    $route.params.block_priority +
                    '/' +
                    $route.params.lesson_id +
                    '/homework'
            "
        />
        <Button
            v-if="
                lesson.homeTasks.length < 1 &&
                    lesson.homeworkProgress &&
                    !evaluationStateButton
            "
            class="lesson__items"
            title="Оценить материал"
            :isLink="true"
            :link="
                '/' +
                    $route.params.slug +
                    '/study/' +
                    $route.params.block_priority +
                    '/' +
                    $route.params.lesson_id +
                    '/evaluation'
            "
        />

        <div
            v-if="lesson.lesson.lesson.type === 'additional'"
            class="specialTasksContentForm"
        >
            <div
                v-if="lesson.additionalLessonStatusByDate === 'openToAnswer' && !lesson.homework"
                class="profileBox profileBox--2"
            >
                <p class="profileBox__title2">Ваш ответ</p>
                <p class="profileBox__text2">
                    Ответить на видео-вопрос нужно до 18:00 четверга по Вашему часовому поясу,
                    иначе Вам будет недоступен ответ от А. Герчика и ответы других студентов.
                </p>
                <div class="form profileBox__form2">
                    <div class="formgroup">
                        <textarea
                            rows="7"
                            placeholder="Введите Ваш ответ"
                            v-model="taskAnswer"
                        ></textarea>
                    </div>
                </div>
                <Button
                    class="profileBox__btn2"
                    title="Отправить"
                    :onClick="() => onSendTaskAnswer()"
                />
            </div>

            <div v-else-if="lesson.homework">
                <div class="profileBox profileBox--2">
                    <p class="profileBox__title2">Ваш ответ отправлен!</p>
                    <p class="profileBox__text2">
                        Оценить ответы других студентов Вы сможете в период с четверга 18:00 (МСК) до пятницы 06:00 (МСК) после открытия видео-ответа на дополнительное задание.
                        <br />
                        В этот период необходимо их прокомментировать, в противном случае по истечению заданного времени, ответы других студентов будут недоступны для Вас.
                    </p>
                </div>

                <Button
                    class="specialTasksContentFooterBtn"
                    title="смотреть правильный ответ"
                    :isLink="lesson.additionalLessonStatusByDate !== 'unknown' &&
                    lesson.additionalLessonStatusByDate !== 'closeEarly' &&
                    lesson.additionalLessonStatusByDate !== 'openToAnswer'"
                    :link="lesson.additionalLessonStatusByDate !== 'unknown' &&
                    lesson.additionalLessonStatusByDate !== 'closeEarly' &&
                    lesson.additionalLessonStatusByDate !== 'openToAnswer' ?
                        '/' +
                        $route.params.slug +
                        '/study/' +
                        $route.params.block_priority +
                        '/' +
                        $route.params.lesson_id +
                        '/correct-answer' :
                        ''"
                    :isDisabled="lesson.additionalLessonStatusByDate === 'unknown' ||
                    lesson.additionalLessonStatusByDate === 'closeEarly' ||
                    lesson.additionalLessonStatusByDate === 'openToAnswer'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import TextArea from "@/views/components/TextArea/TextArea";
import LessonMaterials from "@/views/components/LessonMaterials/LessonMaterials";
import ButtonVideoProblem from "@/views/components/ButtonVideoProblem/ButtonVideoProblem";
import StudyInfoProtector from "@/views/pages/study/studyInfoProtector/studyInfoProtector";

export default {
    title: "lesson",

    components: {
        Button,
        TextArea,
        LessonMaterials,
        ButtonVideoProblem,
        StudyInfoProtector,
    },

    computed: {
        lesson: function() {
            return this.$store.getters.getLesson && this.$store.getters.getLesson.lesson;
        },

        evaluationStateButton() {
            if (
                this.$store.getters.getEvaluation.evaluation.lessonsEvaluation &&
                this.$store.getters.getLesson
            ) {
                return this.$store.getters.getEvaluation.evaluation.lessonsEvaluation.find(
                    (itemId) =>
                        itemId.lessonId ===
                        this.$store.getters.getLesson.lesson.lesson.lesson._id
                )
                    ? this.$store.getters.getEvaluation.evaluation.lessonsEvaluation.find(
                          (itemId) =>
                              itemId.lessonId ===
                              this.$store.getters.getLesson.lesson.lesson.lesson._id
                      ).lessonId
                    : null;
            }
        },
    },

    async beforeMount() {
        await this.updateLessonStore();

        if (
            !(
                this.lesson &&
                this.lesson.lesson &&
                this.lesson.lesson.lesson &&
                this.lesson.lesson.lesson.videos &&
                this.lesson.lesson.lesson.videos.filter(v => v.hasAccessByAccessLevel).length
            ) || !this.lesson.lesson.hasAccesByAccesLevel
        ) {
            if (this.lesson.homeTasks && this.lesson.homeTasks.length || this.lesson.lesson.lesson.type === "algorithm") {
                if (this.lesson.lesson.lesson.type === "algorithm") {
                    await this.$router.push(
                        `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${this.$route.params.lesson_id}/algorithm`
                    );
                } else {
                    await this.$router.push(
                        `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${this.$route.params.lesson_id}/homework`
                    );
                }
            } else {
                this.$router.go(-1);
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "У вас нет доступа к данному уроку.",
                    },
                });
            }
        }
    },

    async mounted() {
        setTimeout(async () => {
            if (this.$route.params.slug && this.$route.params.block_priority && this.$route.params.lesson_id) {
                await this.axios.get(
                    "/programs/" +
                    this.$route.params.slug +
                    "/study/" +
                    this.$route.params.block_priority +
                    "/" +
                    this.$route.params.lesson_id +
                    "/watched"
                );

                if (
                    this.lesson.lesson.lesson.type !== "additional" &&
                    this.lesson.lesson.lesson.type !== "algorithm" &&
                    (!this.lesson.homeTasks ||
                        (this.lesson.homeTasks && this.lesson.homeTasks.length === 0))
                ) {
                    const acceptLessonResponse = await this.axios.get(
                        "/programs/" +
                        this.$route.params.slug +
                        "/study/" +
                        this.$route.params.block_priority +
                        "/" +
                        this.$route.params.lesson_id +
                        "/acceptLesson"
                    );

                    if (
                        acceptLessonResponse &&
                        acceptLessonResponse.data &&
                        acceptLessonResponse.data.result
                    ) {
                        await this.$store.dispatch("setAlert", {
                            alert: {
                                isActive: true,
                                type: "success",
                                text:
                                    acceptLessonResponse.data.answerStatus ||
                                    "Урок был успешно пройден.",
                            },
                        });
                    } else if (
                        acceptLessonResponse &&
                        acceptLessonResponse.data &&
                        acceptLessonResponse.data.error
                    ) {
                        console.error(acceptLessonResponse.data.error);
                    }
                }

                await this.updateLessonStore();
                await this.updateProgressStore();
                await this.updateAdditionalProgressStore();
            }
        }, 35 * 1000);
        

        if (this.lesson && this.lesson.isBan) {
            await this.logout()
        }



        if (!this.vdocipherInited) {
            this.initVdocipher()
        }

        
    },

    watch: {
        "lesson.lesson.lesson.videos": async function(newVal, oldVal) {


            if (newVal[0].vdocipher !== oldVal[0].vdocipher) {
                
                this.$nextTick(() => {
                    this.initVdocipher()
                })
            }
        },
        "$route.params.block_priority": async function() {
            await this.updateLessonStore();

            if (
                !(
                    this.lesson &&
                    this.lesson.lesson &&
                    this.lesson.lesson.lesson &&
                    this.lesson.lesson.lesson.videos &&
                    this.lesson.lesson.lesson.videos.filter(v => v.hasAccessByAccessLevel).length
                ) || !this.lesson.lesson.hasAccesByAccesLevel
            ) {
                if (this.lesson.homeTasks && this.lesson.homeTasks.length || this.lesson.lesson.lesson.type === "algorithm") {
                    if (this.lesson.lesson.lesson.type === "algorithm") {
                        await this.$router.push(
                            `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${this.$route.params.lesson_id}/algorithm`
                        );
                    } else {
                        await this.$router.push(
                            `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${this.$route.params.lesson_id}/homework`
                        );
                    }
                } else {
                    this.$router.go(-1);
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "У вас нет доступа к данному уроку.",
                        },
                    });
                }
            }

        },
    },

    data() {
        return {
            isVisibleTaskAnswer: false,
            isVisibleModal: false,
            isOnboardVideo: this.$store.getters.getIsOnboardVideo.isOnboardVideo,

            taskAnswer: "",
            vdocipherInited: false
        };
    },

    methods: {
        async logout() {
            this.activeDropDown = false;
            await this.axios.post("/auth/logout");
            await this.$store.dispatch("logOut");
            await this.$store.dispatch("clearUserPrograms");
            await this.$store.dispatch("clearUserOldPrograms");
            await this.$store.dispatch("clearUserProfile");
            await this.$router.push("/");
        },
        initVdocipher() {
            if (this.lesson && this.lesson.lesson && this.lesson.lesson.lesson && this.lesson.lesson.lesson.videos) {
                this.vdocipherInited = true
                window.vdo._list = []
                for (let i = 0; i < this.lesson.lesson.lesson.videos.length; i++) {
                    const v = this.lesson.lesson.lesson.videos[i]
                    if (v.vdocipher) {
                        window.vdo.add({
                            otp: v.vdo.otp,
                            playbackInfo: v.vdo.playbackInfo,
                            theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
                            container: document.querySelector( "#vdocipher-" + v.vdo.otp),
                        });
                    }
                }
                
            }
        },
        onOpenModal() {
            this.isVisibleModal = true;
        },
        onCloseModal() {
            this.isVisibleModal = false;
        },

        async updateProgressStore() {
            const getProgress = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/progress"
            );

            if (getProgress && getProgress.data && getProgress.data.result) {
                await this.$store.dispatch("setUserProgress", getProgress.data);
            } else {
                console.error(
                    "Ошибка при поиске основных блоков: " + getProgress.data.error
                );
                await this.$store.dispatch("clearUserProgress");
            }
        },

        async updateAdditionalProgressStore() {
            const getAdditionalProgress = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/progress/additional"
            );

            if (
                getAdditionalProgress &&
                getAdditionalProgress.data &&
                getAdditionalProgress.data.result
            ) {
                await this.$store.dispatch(
                    "setUserAdditionalProgress",
                    getAdditionalProgress.data
                );
            } else {
                console.error(
                    "Ошибка при поиске дополнительных блоков: " +
                        (getAdditionalProgress.data.error || "")
                );
                await this.$store.dispatch("clearUserAdditionalProgress");
            }
        },

        async updateLessonStore() {
            const getLesson = await this.axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/study/" +
                    this.$route.params.block_priority +
                    "/" +
                    this.$route.params.lesson_id
            );

            if (getLesson && getLesson.data && getLesson.data.result) {
                await this.$store.dispatch("setLesson", getLesson.data);
            } else {
                console.error("Ошибка при поиске урока: " + (getLesson.data.error || ""));
                await this.$store.dispatch("clearLesson");
                await this.$router.push("/" + this.$route.params.slug + "/study");

                if (getLesson.data && getLesson.data.error) {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: getLesson.data.error,
                        },
                    });
                }
            }
        },

        async onSendTaskAnswer() {
            if (this.taskAnswer) {
                const getAdditionalAnswerResponse = await this.axios.post(
                    "/programs/" +
                    this.$route.params.slug +
                    "/study/" +
                    this.$route.params.block_priority +
                    "/" +
                    this.$route.params.lesson_id +
                    "/answer/additional", {
                        text: this.taskAnswer.trim()
                    }
                );

                if (getAdditionalAnswerResponse && getAdditionalAnswerResponse.data && getAdditionalAnswerResponse.data.result) {
                    await this.updateLessonStore();
                    this.isVisibleTaskAnswer = true;
                }
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: "Заполните поле для ответа.",
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss">
@import "./lesson.scss";
</style>
