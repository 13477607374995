var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lesson"},[_c('p',{staticClass:"h3"},[_vm._v(" "+_vm._s(_vm.lesson.lesson.lesson.title)+" ")]),(_vm.lesson.lesson.lesson.videos.length)?_c('div',[_vm._l((_vm.lesson.lesson.lesson.videos),function(video,index){return (video.hasAccessByAccessLevel)?_c('div',{key:video._id},[(_vm.lesson.vdocipher && video.vdocipher && video.vdo)?[(_vm.lesson.isVideoEnabled)?[_c('div',{staticClass:"vdocipher-container lesson__video",staticStyle:{"width":"1280px","max-width":"100%","height":"auto"},attrs:{"id":'vdocipher-' + video.vdo.otp}})]:[_c('div',{staticClass:"error"},[_vm._v("Превышен лимит устройств")])]]:[(_vm.lesson.isVideoEnabled)?[((_vm.lesson.lesson.lesson.type !== 'additional' || video.additionalType !== 'answer'))?_c('a',{staticClass:"lesson__video",attrs:{"target":"_blank","href":_vm.lesson.key ? video.url + '?sn=' + _vm.lesson.key : video.url}},[(_vm.lesson.lesson.lesson.type !== 'additional')?_c('div',{staticClass:"imgBlock"},[_c('img',{staticClass:"imgBlock",attrs:{"alt":"video","src":video.preview
                                        ? _vm.$oldClientTestUrl + video.preview
                                        : 'https://edu.gerchik.ru/img/videoPreview.png'}}),_c('img',{staticClass:"imgBlock__btn imgBlock__btn--play",attrs:{"alt":"video","src":require("@/assets/img/pages/study/play2.png")}})]):_c('iframe',{attrs:{"width":"100%","height":"376","src":_vm.lesson.key ? video.url + '?sn=' + _vm.lesson.key : video.url,"title":"Vimeo video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_vm._e()]:[_c('div',{staticClass:"error"},[_vm._v("Превышен лимит устройств")])]]],2):_c('div',{staticClass:"lesson__video"},[(_vm.lesson.lesson.lesson.type !== 'additional')?_c('div',{staticClass:"imgBlock"},[_c('img',{staticClass:"imgBlock imgBlock--disabled",attrs:{"alt":"video","src":video.preview
                                ? _vm.$oldClientTestUrl + video.preview
                                : _vm.$oldClientTestUrl + 'img/videoPreview.png'}}),_c('img',{staticClass:"imgBlock__btn imgBlock__btn--lock",attrs:{"alt":"video","src":require("@/assets/img/pages/study/lock2.svg")}})]):_c('iframe',{staticClass:"imgBlock imgBlock--disabled",attrs:{"width":"100%","height":"376","src":_vm.lesson.key ? video.url + '?sn=' + _vm.lesson.key : video.url,"title":"Vimeo video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])}),(_vm.lesson.vdocipher && _vm.lesson.lesson.lesson.videos[0].vdocipher)?_c('div'):_c('div',{staticClass:"lesson__buttons"},[(_vm.isVisibleModal)?_c('StudyInfoProtector',{attrs:{"onClose":function () { return _vm.onCloseModal(); }}}):_vm._e(),_c('ButtonVideoProblem',{attrs:{"title":"трудности с воспроизведением ?"}})],1)],2):_vm._e(),(_vm.lesson.materials.length)?_c('LessonMaterials',{attrs:{"title":"Дополнительные материалы","materials":_vm.lesson.materials}}):_vm._e(),(_vm.lesson.showHWButton && _vm.lesson.homeTasks && _vm.lesson.homeTasks.length)?_c('Button',{staticClass:"lesson__items",attrs:{"title":Number(this.$route.params.block_priority) === 10
                    ? 'Написание сделки'
                    : 'Выполнить задание',"isLink":true,"link":'/' +
                    _vm.$route.params.slug +
                    '/study/' +
                    _vm.$route.params.block_priority +
                    '/' +
                    _vm.$route.params.lesson_id +
                    '/homework'}}):_vm._e(),(
                _vm.lesson.homeTasks.length < 1 &&
                    _vm.lesson.homeworkProgress &&
                    !_vm.evaluationStateButton
            )?_c('Button',{staticClass:"lesson__items",attrs:{"title":"Оценить материал","isLink":true,"link":'/' +
                    _vm.$route.params.slug +
                    '/study/' +
                    _vm.$route.params.block_priority +
                    '/' +
                    _vm.$route.params.lesson_id +
                    '/evaluation'}}):_vm._e(),(_vm.lesson.lesson.lesson.type === 'additional')?_c('div',{staticClass:"specialTasksContentForm"},[(_vm.lesson.additionalLessonStatusByDate === 'openToAnswer' && !_vm.lesson.homework)?_c('div',{staticClass:"profileBox profileBox--2"},[_c('p',{staticClass:"profileBox__title2"},[_vm._v("Ваш ответ")]),_c('p',{staticClass:"profileBox__text2"},[_vm._v(" Ответить на видео-вопрос нужно до 18:00 четверга по Вашему часовому поясу, иначе Вам будет недоступен ответ от А. Герчика и ответы других студентов. ")]),_c('div',{staticClass:"form profileBox__form2"},[_c('div',{staticClass:"formgroup"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.taskAnswer),expression:"taskAnswer"}],attrs:{"rows":"7","placeholder":"Введите Ваш ответ"},domProps:{"value":(_vm.taskAnswer)},on:{"input":function($event){if($event.target.composing){ return; }_vm.taskAnswer=$event.target.value}}})])]),_c('Button',{staticClass:"profileBox__btn2",attrs:{"title":"Отправить","onClick":function () { return _vm.onSendTaskAnswer(); }}})],1):(_vm.lesson.homework)?_c('div',[_vm._m(0),_c('Button',{staticClass:"specialTasksContentFooterBtn",attrs:{"title":"смотреть правильный ответ","isLink":_vm.lesson.additionalLessonStatusByDate !== 'unknown' &&
                    _vm.lesson.additionalLessonStatusByDate !== 'closeEarly' &&
                    _vm.lesson.additionalLessonStatusByDate !== 'openToAnswer',"link":_vm.lesson.additionalLessonStatusByDate !== 'unknown' &&
                    _vm.lesson.additionalLessonStatusByDate !== 'closeEarly' &&
                    _vm.lesson.additionalLessonStatusByDate !== 'openToAnswer' ?
                        '/' +
                        _vm.$route.params.slug +
                        '/study/' +
                        _vm.$route.params.block_priority +
                        '/' +
                        _vm.$route.params.lesson_id +
                        '/correct-answer' :
                        '',"isDisabled":_vm.lesson.additionalLessonStatusByDate === 'unknown' ||
                    _vm.lesson.additionalLessonStatusByDate === 'closeEarly' ||
                    _vm.lesson.additionalLessonStatusByDate === 'openToAnswer'}})],1):_vm._e()]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profileBox profileBox--2"},[_c('p',{staticClass:"profileBox__title2"},[_vm._v("Ваш ответ отправлен!")]),_c('p',{staticClass:"profileBox__text2"},[_vm._v(" Оценить ответы других студентов Вы сможете в период с четверга 18:00 (МСК) до пятницы 06:00 (МСК) после открытия видео-ответа на дополнительное задание. "),_c('br'),_vm._v(" В этот период необходимо их прокомментировать, в противном случае по истечению заданного времени, ответы других студентов будут недоступны для Вас. ")])])}]

export { render, staticRenderFns }